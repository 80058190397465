import { ajax, updateBlockModelCallback } from 'OK/utils/utils';
import { parent, byClass } from 'OK/utils/dom';
import { activate as activateAutofocus } from 'OK/autofocus';

export default class SearchInput {
    constructor() {
        this.loading = false;
    }

    onFinish() {
        this.wrapper.classList.remove('search-input_searching');
    }

    onChange() {
        var data = {};
        data[this.input.name] = this.input.value.trim();
        if (this.dynamicParamsNames) {
            for (var i = 0; i < this.dynamicParamsNames.length; i++) {
                var paramName = this.dynamicParamsNames[i];
                if (this.input.hasAttribute(paramName)) {
                    data[paramName] = this.input.getAttribute(paramName);
                }
            }
        }
        if (this.request && this.cancelRequests) {
            /* Если предыдущий запрос не завершился — отменяем */
            this.request.xhr.abort();
        }
        this.request = ajax({
            type: this.requestMethod,
            url: this.url,
            data: data
        }).done(function (data, status, xhr) {
            this.onFinish();
            updateBlockModelCallback(data, status, xhr);
            this.input.dispatchEvent(new CustomEvent('searchEnd', {bubbles: true}));
        }.bind(this));
        if (findUriParameterValue("st.cmd", this.url) === "altGroupAdvertsPage" && findUriParameterValue("st.search", this.url) === "on") {
            // при поиске объявлений в группе искусственно переходим на поисковый стейт,
            // чтобы можно было вернуться назад к "не поисковому" состоянию и обратно к "поисковому"
            var location = OK.historyManager.getState();
            var postfix = "/search";
            if (location.indexOf(postfix, location.length - postfix.length) === -1) {
                if (location[location.length - 1] === '/') {
                    location = location.slice(0, -1);
                }
                if (findUriParameterValue("st.selection", this.url) === "") {
                    location = location.replace(/\/[0-9]+$/, "");
                }
                OK.historyManager.pushState(location + postfix);
            }
        }
    }

    clear() {
        this.input.value = '';
        this.previous = '';
        this.wrapper.classList.remove('search-input_active');
        this.toggleNeighbours(true);
        onChanging(this);
        this.onChange();
        var paramsButton = this.wrapper.querySelector('.js-search-parameters:not(.js-grid)');
        if (paramsButton) {
            paramsButton.classList.add('invisible');
        }
        if (this.advertsBack) {
            var toGridButton = this.wrapper.querySelector('.js-search-parameters.js-grid');
            toGridButton.classList.remove('invisible');
        }
    }

    isEmpty() {
        return this.input.value.trim().length === 0;
    }

    handleEvent(e) {
        var type = e.type;
        if (type === 'click') {
            this.clear();
            return;
        } else if (type === 'keydown') {

            switch (e.which) {
                // enter
                case 13:
                    activeSearch(this, true);
                    break;

                // esc
                case 27:
                    if (this.isEmpty()) {
                        this.input.blur();
                    } else {
                        this.clear();
                    }
                    e.stopPropagation(); //чтобы леер не закрывался
                    break;
            }

        } else {
            activeSearch(this, false);
        }
        if (this.isEmpty()) {
            this.wrapper.classList.remove('search-input_active');
            this.toggleNeighbours(true);
        } else {
            this.wrapper.classList.add('search-input_active');
            this.toggleNeighbours(false);
        }
    }

    toggleNeighbours(show) {
        // прячем при поиске и возвращаем по окончанию
        var hideCls = this.hideOnSearchClass;
        if (this.hideOnSearchEls) {
            this.hideOnSearchEls.forEach(function (el) {
                el.classList.toggle(hideCls, !show);
            });
        }
    }

    activate(element) {
        var closeElements;

        this.input = element;
        this.previous = this.input.value.trim();
        this.wrapper = element.parentNode.parentNode;
        this.url = element.getAttribute('data-url');
        this.requestMethod = element.getAttribute('data-rq-method') || "GET";
        this.cancelRequests = element.getAttribute('data-cancel-requests') === 'true';
        this.timeout = parseInt(element.getAttribute('data-searchtimeout'), 10);
        this.advertsBack = element.getAttribute('data-adverts-back') === 'true';
        var dynamic = element.getAttribute('data-dynamic_params_names');
        if (dynamic) {
            this.dynamicParamsNames = dynamic.split(',').filter(function (s) {
                return s.length > 0;
            });
        }
        element.addEventListener('keydown', this, false);
        element.addEventListener('keyup', this, false);
        element.addEventListener('keypress', this, false);
        element.addEventListener('paste', this, false);
        closeElements = this.wrapper.getElementsByClassName('ic_close-g');
        if (closeElements.length === 1) {
            this.close = closeElements[0];
            this.close.addEventListener('click', this, false);
        }

        var hideParent = parent(element, "js-hide-on-search-wr");
        if (hideParent) {
            /** @type {HTMLElement[]} */
            this.hideOnSearchEls = byClass(hideParent, "js-hide-on-search");
            this.hideOnSearchClass = element.getAttribute('data-hide-on-search-class') || 'anim_shrink';
        }


        var autofocusEnabled = element.getAttribute('data-autofocus') === 'true';
        if (autofocusEnabled) {
            activateAutofocus(element);
        }
    }

    deactivate(element) {
        element.removeEventListener('keydown', this, false);
        element.removeEventListener('keyup', this, false);
        element.removeEventListener('keypress', this, false);
        element.removeEventListener('paste', this, false);
        if (this.close) {
            this.close.removeEventListener('click', this, false);
        }
    }
}

function valueChanged(self) {
    return self.input.value.trim() !== self.previous;
}

function onChanging(self) {
    self.wrapper.classList.add('search-input_searching');
}

function activeSearch(self, force) {
    if (!(force || valueChanged(self))) {
        return;
    }
    self.previous = self.input.value.trim();

    if (self.timer) {
        clearTimeout(self.timer);
    }

    onChanging(self);
    self.timer = setTimeout(self.onChange.bind(self), self.timeout);
}

function findUriParameterValue(parameter, url) {
    var params = url.substring(Math.max(0, url.indexOf('?') + 1)).split('&');

    for (var i = 0; i < params.length; i++) {
        var param = params[i];
        var indexOf = param.indexOf(parameter + '=');
        if (indexOf === 0) {
            return param.substr(parameter.length + 1);
        }
    }
    return "";
}
